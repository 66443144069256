$font-family-base: 'Open Sans', sans-serif;

$enable-gradients: true;
$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;


$white:    #fff;
$gray-100: #ecf0f5;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #3F4348;
$black:    #000;

$grays: ();
$grays: map-merge(
                (
                        "100": $gray-100,
                        "200": $gray-200,
                        "300": $gray-300,
                        "400": $gray-400,
                        "500": $gray-500,
                        "600": $gray-600,
                        "700": $gray-700,
                        "800": $gray-800,
                        "900": $gray-900
                ),
                $grays
);

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: ();
$colors: map-merge(
                (
                        "blue":       $blue,
                        "indigo":     $indigo,
                        "purple":     $purple,
                        "pink":       $pink,
                        "red":        $red,
                        "orange":     $orange,
                        "yellow":     $yellow,
                        "green":      $green,
                        "teal":       $teal,
                        "cyan":       $cyan,
                        "white":      $white,
                        "gray-light": $gray-100,
                        "gray":       $gray-600,
                        "gray-dark":  $gray-800
                ),
                $colors
);

$primary: #3C80C6;
$info: #3C80C6;
$success: #00BE58;

$border-radius: 5px;
$border-color: #D8D8D8;
